// Javascript
require("@rails/ujs").start()
require("@rails/activestorage").start()
import '../src/recruit/application'

// 画像読み込み設定
const images = require.context('../images', true);
const imagePath = (name) => images(name, true)

// SASS
import '../stylesheets/recruit/application.scss'